import { useNuxtApp } from "nuxt/app";
import axios from "axios";
import type {
  CodeConfirmRequest,
  ResendCodeRequest,
  SignUpRequest,
  SignUpRequestOptions,
} from "@/components/SidebarRegister/protocols";
import type {
  ConfirmationCodeForgetPasswordRequest,
  ForgotPasswordDrawerFormRequest,
} from "@/components/ForgotPasswordDrawer/protocols";

export async function getAccountById(accountId: string) {
  const Api = useNuxtApp().$api;
  try {
    const httpResponse = await Api.get(`/account/${accountId}`);
    return httpResponse;
  } catch (error: any) {
    const errorMessage =
      error?.response?.data?.data?.message || "Ocorreu um erro";
    throw new Error(errorMessage);
  }
}

export async function refreshToken(nid: string, refreshToken: string) {
  const Api = useNuxtApp().$api;
  try {
    const httpResponse = await Api.post(`/account/notauth/refresh-token`, {
      nid,
      refresh_token: refreshToken,
    });
    return httpResponse;
  } catch (error: any) {
    const errorMessage =
      error?.response?.data?.data?.message || "Ocorreu um erro";
    throw new Error(errorMessage);
  }
}

export async function signIn(nid: string, password: string) {
  const Api = useNuxtApp().$api;
  try {
    const httpResponse = await Api.post(`/account/notauth/login`, {
      nid,
      password,
    });
    return httpResponse;
  } catch (error: any) {
    const errorMessage =
      error?.response?.data?.data?.message || "Ocorreu um erro";
    throw new Error(errorMessage);
  }
}

export async function signUp(
  signUpData: SignUpRequest,
  options: SignUpRequestOptions = {}
) {
  const Api = useNuxtApp().$api;
  try {
    const httpResponse = await Api.post(`/account/notauth`, signUpData, {
      params: { md5: options.md5 },
    });
    return httpResponse;
  } catch (error: any) {
    const errorMessage =
      error?.response?.data?.data?.message || "Ocorreu um erro";
    throw new Error(errorMessage);
  }
}

export async function codeConfirm(codeConfirmRequest: CodeConfirmRequest) {
  const Api = useNuxtApp().$api;
  try {
    const httpResponse = await Api.post(
      `/account/notauth/confirm`,
      codeConfirmRequest
    );
    return httpResponse;
  } catch (error: any) {
    const errorMessage =
      error?.response?.data?.data?.message || "Ocorreu um erro";
    throw new Error(errorMessage);
  }
}

export async function forgotPassword(
  forgotPasswordRequest: ForgotPasswordDrawerFormRequest
) {
  const Api = useNuxtApp().$api;
  try {
    const httpResponse = await Api.post(
      `/account/notauth/forgot`,
      forgotPasswordRequest
    );
    return httpResponse;
  } catch (error: any) {
    const errorMessage =
      error?.response?.data?.data?.message || "Ocorreu um erro";
    throw new Error(errorMessage);
  }
}

export async function forgotPasswordResendCodeEmail(payload: {
  nid: string;
  platform: string;
  device: string;
}) {
  const Api = useNuxtApp().$api;
  try {
    const httpResponse = await Api.post(
      "/account/notauth/forgot/email",
      payload
    );
    return httpResponse;
  } catch (error: any) {
    const errorMessage =
      error?.response?.data?.data?.message || "Ocorreu um erro";
    throw new Error(errorMessage);
  }
}

export async function confirmForgotPassword(
  confirmationCodeForgetPasswordRequest: ConfirmationCodeForgetPasswordRequest
) {
  const Api = useNuxtApp().$api;
  try {
    const httpResponse = await Api.post(
      `/account/notauth/confirm-password?md5=true`,
      confirmationCodeForgetPasswordRequest
    );
    return httpResponse;
  } catch (error: any) {
    const errorMessage =
      error?.response?.data?.data?.message || "Ocorreu um erro";
    throw new Error(errorMessage);
  }
}

export async function resendCodeByEmail(payload: ResendCodeRequest) {
  const Api = useNuxtApp().$api;
  try {
    const httpResponse = await Api.post(
      "account/notauth/resend/email",
      payload
    );
    return httpResponse;
  } catch (error: any) {
    const errorMessage =
      error?.response?.data?.data?.message || "Ocorreu um erro";
    throw new Error(errorMessage);
  }
}

export async function getConstants() {
  const Api = useNuxtApp().$api;
  try {
    const httpResponse = await Api.get(`/account/notauth/constants`);
    return httpResponse;
  } catch (error: any) {
    const errorMessage =
      error?.response?.data?.data?.message || "Ocorreu um erro";
    throw new Error(errorMessage);
  }
}

export async function checkEmail({ email }: { email: string }) {
  const env = useRuntimeConfig();
  const api = axios.create({ baseURL: env.public.apiRootURL as string });

  const httpResponse = await api.get("/v2/account/v3/notauth/check-email", {
    params: { email },
  });
  return httpResponse;
}

export async function checkPhoneUsed({ phone }: { phone: string }) {
  const env = useRuntimeConfig();
  const api = axios.create({ baseURL: env.public.apiRootURL as string });

  const httpResponse = await api.get("/v2/account/v3/notauth/check-phone", {
    params: { phone: phone.replace(/\D/g, "") },
  });
  return httpResponse;
}

export async function checkNid({ nid }: { nid: string }) {
  const env = useRuntimeConfig();
  const api = axios.create({ baseURL: env.public.apiRootURL as string });

  const httpResponse = await api.get("/v2/account/v3/notauth/check-nid", {
    params: { nid: nid.replace(/\D/g, "") },
  });
  return httpResponse;
}
